
import Vue from 'vue';
import Component from 'vue-class-component';
import AdminLayout from '../../components/layout/AdminLayout.vue';
import TeacherDashboard from '../../components/admin/teachers/TeacherDashboard.vue';

@Component({
  components: { AdminLayout, TeacherDashboard },
})
export default class TeacherPage extends Vue {}


import ForgotPasswordForm from '../../components/admin/login/ForgotPasswordForm.vue';
import AdminLayout from '../../components/layout/AdminLayout.vue';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  components: {
    AdminLayout,
    ForgotPasswordForm,
  },
})
export default class ForgotPassword extends Vue {}

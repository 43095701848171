import {
  Device,
  Form,
  LettersFantasyWordsResultDto,
  Test,
  TestResultDto,
} from '@/api/types/api';
import dataA from '@/assets/data/form-a/letters.json';
import dataA_CH from '@/assets/data/form-a/letters_CH.json';
import dataB from '@/assets/data/form-b/letters.json';
import dataB_CH from '@/assets/data/form-b/letters_CH.json';
import tutorial from '@/assets/data/tutorials/letters.json';
import { LettersQuestionType, TestStatus } from '@/types';
import axios, { AxiosError } from 'axios';
import {
  Action,
  Module,
  Mutation,
  MutationAction,
  VuexModule,
} from 'vuex-module-decorators';

import { API_RESULTS } from '../../utils/api';
import axiosRetry from 'axios-retry';
import { RETRIES, RETRY_DELAY } from '@/utils/common';

@Module({ namespaced: true, name: 'letters' })
export default class LettersModule extends VuexModule {
  tutorialData = tutorial.letters;
  tutorialAudio = {
    intro: '',
    listen: '',
    clickCorrectLetter: '',
    clickOrangeLetter: '',
    wellDone: '',
    super: '',
    wrong: '',
    rightLetterHint: '',
    readyGo: '',
  };

  chosenForm = Form.unknown;
  startedTest = false;

  result: TestResultDto = {
    test: Test.letters,
    form: this.currentForm,
    device: Device.Unknown,
    questionsAnswered: 0,
    overallQuestions: 0,
    correctlyAnswered: 0,
    overallTime: 0,
    doneTutorial: false,
    answers: [],
  };

  get currentForm(): Form {
    return this.chosenForm;
  }

  get testData(): LettersQuestionType[] {
    const version = localStorage.getItem('version') || 'AT';

    if (version === 'CH') {
      return this.currentForm === Form.a ? dataA_CH.letters : dataB_CH.letters;
    } else {
      return this.currentForm === Form.a ? dataA.letters : dataB.letters;
    }
  }

  get currentQuestion(): LettersQuestionType {
    return this.testData[this.result!.questionsAnswered];
  }

  get overallQuestions(): number {
    return this.testData.length;
  }

  get isDone(): boolean {
    return this.result.done!;
  }

  get hasFetchedData(): boolean {
    return this.result.done != undefined;
  }

  get tutorialQuestions(): LettersQuestionType[] {
    return tutorial.letters;
  }

  get tutorialAudios(): typeof tutorial.tutorial {
    return this.tutorialAudio;
  }

  get hasDoneTutorial(): boolean {
    return this.result!.doneTutorial;
  }

  get currentTestResult(): TestResultDto {
    return this.result!;
  }

  @Mutation
  startTest(): void {
    this.startedTest = true;
  }

  @Mutation
  setForm(value: Form): void {
    this.chosenForm = value;
  }

  @Mutation
  finishedTutorial(): void {
    this.result.doneTutorial = true;
  }

  @Mutation
  resetTutorial(): void {
    this.result.doneTutorial = false;
    if (!this.isDone) {
      this.result.questionsAnswered = 0;
      this.result.correctlyAnswered = 0;
      this.result.overallTime = 0;
      this.result.answers = [];
      this.result.device = Device.Unknown;
    }
  }

  @Mutation
  addAnswer(answer: LettersFantasyWordsResultDto): void {
    if (answer.isCorrect) {
      this.result!.correctlyAnswered += 1;
    }
    this.result!.questionsAnswered += 1;
    this.result!.overallTime += answer.time;
    this.result!.answers.push(answer);
    //if no device associated with test, set device
    if (this.result!.device === Device.Unknown) {
      const isTouch =
        'ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        (navigator as any).msMaxTouchPoints > 0;

      this.result!.device = isTouch ? Device.Tablet : Device.Computer;
    }
  }

  @Mutation
  finishedAndSavedTest(): void {
    this.result.done = true;
  }

  @Mutation
  resetDone(): void {
    // INFO have to reset here when saving fails
    // in case of going back to overview the test will not be disabled
    //  and they can not go further
    this.result.done = false;
  }

  @Action({ rawError: true })
  async saveStatus(id: string): Promise<any> {
    axiosRetry(axios, {
      retries: RETRIES,
      retryDelay: () => RETRY_DELAY,
      retryCondition: (error: AxiosError) => {
        if (
          error?.response?.config?.url?.includes(`results/${id}`) &&
          error?.response?.config?.method === 'patch'
        ) {
          return true;
        }
        return false;
      },
    });
    // INFO set correct overall items and done to true, only saves on isEnd
    this.result.overallQuestions = this.overallQuestions;
    this.result.done = true;
    return await axios.patch(`${API_RESULTS}/${id}`, this.result, {
      headers: { 'Content-Type': 'application/json' },
    });
  }

  @MutationAction({ rawError: true })
  async getTestStatus(args: { id: string; form: Form }): Promise<any> {
    const test = Test.letters;
    const length = dataA.letters.length;
    try {
      const res = await axios.get(
        `${API_RESULTS}/${args.id}/${args.form}/${test}`,
      );

      //set the correct test length if default TestResultDto is returned
      res.data.overallQuestions = length;
      const result = res.data;
      return { result };
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  @Mutation
  initializeTutorial(audios: any) {
    this.tutorialAudio = audios;
    // this.tutorialData = tutorial.letters;
  }

  @Mutation
  reset() {
    this.tutorialAudio = {
      intro: '',
      listen: '',
      clickCorrectLetter: '',
      clickOrangeLetter: '',
      wellDone: '',
      super: '',
      wrong: '',
      rightLetterHint: '',
      readyGo: '',
    };
  }
}

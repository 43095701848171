import {
  Device,
  Form,
  RiddleResultDto,
  Test,
  TestResultDto,
} from '@/api/types/api';
import dataA from '@/assets/data/form-a/riddle.json';
import dataA_CH from '@/assets/data/form-a/riddle_CH.json';
import dataB from '@/assets/data/form-b/riddle.json';
import dataB_CH from '@/assets/data/form-b/riddle_CH.json';
import tutorial from '@/assets/data/tutorials/riddle.json';
import { RiddleQuestionType, TestStatus } from '@/types';
import axios, { AxiosError } from 'axios';
import {
  Action,
  Module,
  Mutation,
  MutationAction,
  VuexModule,
} from 'vuex-module-decorators';

import { API_RESULTS } from '../../utils/api';
import axiosRetry from 'axios-retry';
import { RETRIES, RETRY_DELAY } from '@/utils/common';

@Module({ namespaced: true, name: 'riddle' })
export default class RiddleModule extends VuexModule {
  tutorialData = tutorial.questions;
  tutorialAudio = {
    readQuestion: '',
    clickCorrectWords: '',
    showCorrectWords: '',
    clickArrow: '',
    congrats: '',
    super: '',
    wrong: '',
    readyGo: '',
  };
  chosenForm = Form.unknown;
  shownCongratsA = false;
  shownCongratsB = false;

  result: TestResultDto = {
    test: Test.riddle,
    form: this.currentForm,
    device: Device.Unknown,
    questionsAnswered: 0,
    overallQuestions: this.testData.length,
    correctlyAnswered: 0,
    overallTime: 0,
    doneTutorial: false,
    answers: [],
  };

  get currentForm(): Form {
    return this.chosenForm;
  }

  get testData(): RiddleQuestionType[] {
    const version = localStorage.getItem('version') || 'AT';
    if (version === 'CH') {
      return this.currentForm === Form.a
        ? dataA_CH.questions
        : dataB_CH.questions;
    } else {
      return this.currentForm === Form.a ? dataA.questions : dataB.questions;
    }
  }

  get currentQuestion(): RiddleQuestionType {
    return this.testData[this.result.questionsAnswered];
  }

  get overallQuestions(): number {
    return this.testData.length;
  }

  get isDone(): boolean {
    return this.result.done!;
  }

  get hasFetchedData(): boolean {
    return this.result.done != undefined;
  }

  get testStatus(): TestStatus {
    return {
      isFirst: this.result.questionsAnswered === 0,
      isLast: this.result.questionsAnswered === this.result.overallQuestions,
    };
  }

  get tutorialQuestions(): RiddleQuestionType[] {
    return this.tutorialData;
  }

  get tutorialAudios(): any {
    return this.tutorialAudio;
  }

  get hasDoneTutorial(): boolean {
    return this.result.doneTutorial;
  }

  @Mutation
  haveShownCongrats(form: string): void {
    if (form === Form.a) {
      this.shownCongratsA = true;
    } else {
      this.shownCongratsB = true;
    }
  }

  @Mutation
  resetCongrats(): void {
    this.shownCongratsA = false;
    this.shownCongratsB = false;
  }

  @Mutation
  setForm(value: Form): void {
    this.chosenForm = value;
  }

  @Mutation
  finishedTutorial(): void {
    this.result.doneTutorial = true;
  }

  @Mutation
  resetTutorial(): void {
    this.result.doneTutorial = false;
    if (!this.isDone) {
      this.result.questionsAnswered = 0;
      this.result.correctlyAnswered = 0;
      this.result.overallTime = 0;
      this.result.answers = [];
      this.result.device = Device.Unknown;
    }
  }

  @Mutation
  addAnswer(answer: RiddleResultDto): void {
    if (answer.isCorrect) {
      this.result.correctlyAnswered += 1;
    }
    this.result.questionsAnswered += 1;
    this.result.overallTime += answer.time;
    this.result.answers.push(answer);
    //if no device associated with test, set device
    if (this.result!.device === Device.Unknown) {
      const isTouch =
        'ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        (navigator as any).msMaxTouchPoints > 0;

      this.result!.device = isTouch ? Device.Tablet : Device.Computer;
    }
  }

  @Mutation
  finishedAndSavedTest(): void {
    this.result.done = true;
  }

  @Mutation
  resetDone(): void {
    // INFO have to reset here when saving fails
    // in case of going back to overview the test will not be disabled
    //  and they can not go further
    this.result.done = false;
  }

  @Action({ rawError: true })
  async saveStatus(id: string): Promise<any> {
    axiosRetry(axios, {
      retries: RETRIES,
      retryDelay: () => RETRY_DELAY,
      retryCondition: (error: AxiosError) => {
        if (
          error?.response?.config?.url?.includes(`results/${id}`) &&
          error?.response?.config?.method === 'patch'
        ) {
          return true;
        }
        return false;
      },
    });
    this.result.done = true;
    return await axios.patch(`${API_RESULTS}/${id}`, this.result, {
      headers: { 'Content-Type': 'application/json' },
    });
  }

  @MutationAction({ rawError: true })
  async getTestStatus(args: { id: string; form: string }): Promise<any> {
    const test = Test.riddle;
    const length = dataA.questions.length;
    try {
      const res = await axios.get(
        `${API_RESULTS}/${args.id}/${args.form}/${test}`,
      );
      //set the correct test length if default TestResultDto is returned
      res.data.overallQuestions = length;
      const result = res.data;
      return { result };
    } catch (error) {
      return error;
    }
  }

  @Mutation
  initializeTutorial(audios: any) {
    this.tutorialAudio = audios;
    this.tutorialData = tutorial.questions;
  }

  @Mutation
  reset() {
    this.tutorialAudio = {
      readQuestion: '',
      clickCorrectWords: '',
      showCorrectWords: '',
      clickArrow: '',
      congrats: '',
      super: '',
      wrong: '',
      readyGo: '',
    };
  }
}

import axios from 'axios';
import { API_LOG } from './api';
import { UserDto } from '@/api/types';
import localforage from 'localforage';

type Severity =
  | 'info'
  | 'warning'
  | 'error'
  | 'critical'
  | 'fatal'
  | 'exception'
  | 'debug'
  | 'trace';

const consoleError = console.error;
const consoleWarn = console.warn;
const consoleException = (console as any).exception;

export const logToServer = async (
  severity: Severity,
  message: string,
  user?: UserDto,
) => {
  if (!user)
    try {
      localforage.config({ storeName: 'lesikus', version: 2 });
      const vuex = (await localforage.getItem('vuex')) as any;
      vuex?.user?.user && (user = vuex.user.user);
    } catch (error) {
      consoleError.apply(console, ['Could not get user-infos', error]);
    }

  try {
    await axios.post(API_LOG, { user, severity, message });
  } catch (error) {
    consoleError.apply(console, ['Could not log to server', error]);
  }
};

export const logErrors = () => {
  console.error = function (...args: any[]) {
    consoleError.apply(console, args);
    logToServer('error', args.join(' '));
  };

  console.warn = function (...args: any[]) {
    consoleWarn.apply(console, args);
    logToServer('warning', args.join(' '));
  };

  (console as any).exception = function (...args: any[]) {
    consoleException.apply(console, args);
    logToServer('exception', args.join(' '));
  };

  window.onerror = function (message, source, lineno, colno, error) {
    console.error(
      '' + message + ' ' + source + ' ' + lineno + ' ' + colno + ' ' + error,
    );
  };

  window.onunhandledrejection = function (event: PromiseRejectionEvent) {
    console.log('unhandledrejection: ', event);
    console.error('unhandledrejection: ', event.reason);
  };

  console.log('%cWillkommen beim Leo-Lesetest!', 'font-size: x-large');
  console.log(' ');
  console.log(
    `Version: ${process.env.VUE_APP_VERSION} (vcs-revision: ${process.env.VUE_APP_VCS_HASH})`,
  );
  console.log(' ');
};


import AdminSnackbar from '../../components/admin/base/AdminSnackbar.vue';
import EvaluationButton from '../../components/admin/students/table/EvaluationButton.vue';
import GeneralHeader from '../../components/base/GeneralHeader.vue';
import GeneralLayout from '../../components/layout/GeneralLayout.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import UserModule from '@/store/modules/UserModule';
import { getModule } from 'vuex-module-decorators';
import { UserDto } from '@/api/types/api';
import { getSchoolPrefix } from '@/utils/common';

@Component({
  components: {
    GeneralLayout,
    GeneralHeader,
    EvaluationButton,
    AdminSnackbar,
  },
})
export default class ParentInfo extends Vue {
  userModule: UserModule = getModule(UserModule, this.$store);

  loading = false;

  // audios = ['/audio/intro.mp3', '/audio/show.mp3', '/audio/super.mp3'];

  get user(): UserDto {
    return this.userModule.currentUser;
  }

  get doneA(): boolean {
    return this.userModule.doneA.toString() === 'true';
  }

  get doneB(): boolean {
    return this.userModule.doneB.toString() === 'true';
  }

  get enabledA(): boolean {
    return this.userModule.enabledA.toString() === 'true';
  }

  get enabledB(): boolean {
    return this.userModule.enabledB.toString() === 'true';
  }

  get noTestEnabled(): boolean {
    return !this.enabledA && !this.enabledB;
  }

  get hasFinishedEnabledTest(): boolean {
    if (this.enabledA && this.enabledB) {
      return this.doneA && this.doneB;
    } else if (this.enabledA) {
      return this.doneA;
    } else {
      return this.doneB;
    }
  }

  get hasFinishedTests(): boolean {
    return Boolean(this.userModule.doneA && this.userModule.doneB);
  }

  goToStart(): void {
    this.$router.push({
      name: 'start',
      params: { schoolName: getSchoolPrefix() },
    });
  }

  async mounted() {
    // this.loading = true;
    // try {
    //   this.audios.forEach((audio) => {
    //     new Audio().src = audio;
    //   });
    // } catch {
    //   console.log('err: preloading audios');
    // } finally {
    //   this.loading = false;
    // }
  }
}


import GeneralHeader from '../../components/base/GeneralHeader.vue';
import GeneralLayout from '../../components/layout/GeneralLayout.vue';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  components: {
    GeneralLayout,
    GeneralHeader,
  },
})
export default class Impressum extends Vue {
  get title(): string {
    return 'Impressum';
  }
}

export const API_LOGIN = `${process.env.VUE_APP_API_URL || ''}/auth/login`;
export const API_CURRENT_USER = `${
  process.env.VUE_APP_API_URL || ''
}/auth/current-user`;
export const API_RESET_PASSWORD = `${
  process.env.VUE_APP_API_URL || ''
}/auth/reset-password`;
export const API_FORGOT_PASSWORD = `${
  process.env.VUE_APP_API_URL || ''
}/auth/forgot-password`;
export const API_REFRESH_TOKEN = `${
  process.env.VUE_APP_API_URL || ''
}/auth/refresh`;

export const API_USERS = `${process.env.VUE_APP_API_URL || ''}/users`;

export const API_RESULTS = `${process.env.VUE_APP_API_URL || ''}/results`;
export const API_PDF_EVALUATION = `${process.env.VUE_APP_API_URL || ''}/pdf`;

export const API_GET_TEACHERS = `${
  process.env.VUE_APP_API_URL || ''
}/users/teachers`;

export const API_GET_CLASSES = `${process.env.VUE_APP_API_URL || ''}/schools`;

export const API_CLIENTS = `${process.env.VUE_APP_API_URL || ''}/clients`;
export const API_PDF_BOOK = `${
  process.env.VUE_APP_API_URL || ''
}/pdf/download/school`;

export const API_LOG = `${process.env.VUE_APP_API_URL || ''}/log`;


import store from '@/store';
import LettersModule from '@/store/modules/LettersModule';
import SnackbarModule from '@/store/modules/SnackbarModule';
import UserModule from '@/store/modules/UserModule';
import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';
import GeneralHeader from '../../components/base/GeneralHeader.vue';
import GeneralLayout from '../../components/layout/GeneralLayout.vue';
import LettersTestContainer from '../../components/tests/letters/LettersTestContainer.vue';
import LettersTutorialContainer from '../../components/tests/letters/LettersTutorialContainer.vue';

@Component({
  components: {
    LettersTestContainer,
    LettersTutorialContainer,
    GeneralLayout,
    GeneralHeader,
  },
})
export default class Buchstabenjagd extends Vue {
  lettersModule: LettersModule = getModule(LettersModule, this.$store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);

  get doneTutorial(): boolean {
    return this.lettersModule.hasDoneTutorial;
  }

  async beforeRouteLeave(to: any, from: any, next: () => void): Promise<void> {
    await (store as any).restored;
    this.userModule.stopActiveAudio();
    // this.lettersModule.reset();
    next();
  }
}

import Vue from 'vue';
import store from '../store';
import axios from 'axios';

export const clickEventBus = new Vue();

export const clickSound = {
  play: async (): Promise<void> => {
    clickEventBus.$emit('play');
  },
};

export const checkAuthentication = (
  to: any,
  next: (arg0?: { name: string }) => void,
): void => {
  const isAuthenticated = store.getters.authenticated;
  if (to.name !== 'login' && !isAuthenticated) {
    next({ name: 'login' });
  } else {
    next();
  }
};

export const getRandomInt = (max: number): number => {
  return Math.floor(Math.random() * max);
};

export const generateRandomString = (
  source: string,
  length: number,
): string => {
  const result = [];
  const charactersLength = source.length;
  for (let i = 0; i < length; ++i) {
    result.push(source.charAt(Math.floor(Math.random() * charactersLength)));
  }
  return result.join('');
};

export const DEFAULT_PASSWORD = generateRandomString(
  'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789#!_+*[]',
  15,
);

export const getNameAbbr = (
  firstname: string,
  lastname: string,
  isTherapyOrParent = false,
): string => {
  const source = (firstname.replace(/ /g, '') + lastname.replace(/ /g, ''))
    .replace('-', '')
    .trim();
  const name = isTherapyOrParent
    ? `${generateRandomString(source, 6)}`.toLowerCase() + getRandomInt(9999)
    : `${firstname
        .replace(/ /g, '')
        .replaceAll('-', '')
        .substring(0, 3)
        .trim()}${lastname
        .replace(/ /g, '')
        .replaceAll('-', '')
        .substring(0, 3)
        .trim()}`.toLowerCase() + getRandomInt(999);
  return name;
};

export const getDefaultPassword = (
  firstname: string,
  lastname: string,
): string => {
  return `${firstname
    .toLowerCase()
    .replace(/ /g, '')
    .replaceAll('-', '')
    .substring(0, 3)
    .trim()}${lastname
    .toLowerCase()
    .replace(/ /g, '')
    .replaceAll('-', '')
    .substring(0, 3)
    .trim()}`;
};

export const setSchoolPrefix = (prefix: string): void => {
  localStorage.setItem('schoolPrefix', prefix);
};

export const getSchoolPrefix = (): string => {
  return localStorage.getItem('schoolPrefix') ?? '';
};

export const precacheFiles = async (fileUrls: string[]) => {
  if (fileUrls.length === 0) return;
  const cacheName = `leo-cache-precache-v2-${window.location.origin}/`;
  const cache = await caches.open(cacheName);
  await Promise.all(
    fileUrls.map(async (fileUrl) => {
      const cacheMatch = await cache.match(fileUrl, { ignoreSearch: true });
      // console.log('cacheMatch', cacheMatch);
      return cacheMatch || (await fetch(fileUrl));
    }),
  );
};


export const RETRIES = 3;
export const RETRY_DELAY = 3000;
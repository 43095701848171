import {
  Form,
  TestResultDto,
  Test,
  Device,
  LettersFantasyWordsResultDto,
} from '@/api/types/api';
import dataA from '@/assets/data/form-a/fantasy.json';
import dataB from '@/assets/data/form-b/fantasy.json';
import tutorial from '@/assets/data/tutorials/fantasy.json';
import { FantasyWordQuestionType, TestStatus } from '@/types';
import axios, { AxiosError } from 'axios';
import {
  Action,
  Module,
  Mutation,
  MutationAction,
  VuexModule,
} from 'vuex-module-decorators';
// import axiosRetry from 'axios-retry';
import { API_RESULTS } from '../../utils/api';
import { RETRIES, RETRY_DELAY } from '@/utils/common';
import axiosRetry from 'axios-retry';

@Module({ namespaced: true, name: 'fantasy' })
export default class FantasyModule extends VuexModule {
  tutorialData = tutorial.words;
  tutorialAudio = {
    bothRead: '',
    maxReads: '',
    lisaReads: '',
    clickCorrectImage: '',
    clickOrangeAnswer: '',
    whoReadCorrectly: '',
    wellDone: '',
    super: '',
    wrongMaxCorrect: '',
    wrongLisaCorrect: '',
    readyGo: '',
  };
  chosenForm: Form = Form.unknown;
  imgFormat = 'webp';

  result: TestResultDto = {
    test: Test.fantasy,
    form: this.currentForm,
    device: Device.Unknown,
    questionsAnswered: 0,
    overallQuestions: this.testData.length,
    correctlyAnswered: 0,
    overallTime: 0,
    doneTutorial: false,
    answers: [],
  };

  get imageFormat(): string {
    return this.imgFormat;
  }

  get currentForm(): Form {
    return this.chosenForm;
  }

  get testData(): FantasyWordQuestionType[] {
    return this.currentForm === Form.a ? dataA.words : dataB.words;
  }

  get currentQuestion(): FantasyWordQuestionType {
    return this.testData[this.result.questionsAnswered];
  }

  get overallQuestions(): number {
    return this.testData.length;
  }

  get isDone(): boolean {
    return this.result.done!;
  }

  get hasFetchedData(): boolean {
    return this.result.done != undefined;
  }

  get testStatus(): TestStatus {
    return {
      isFirst: this.result.questionsAnswered === 0,
      isLast: this.result.questionsAnswered === this.result.overallQuestions,
    };
  }

  get tutorialQuestions(): FantasyWordQuestionType[] {
    return this.tutorialData;
  }

  get tutorialAudios(): any {
    return this.tutorialAudio;
  }

  get hasDoneTutorial(): boolean {
    return this.result.doneTutorial;
  }

  @Mutation
  setImageFormat(): void {
    this.imgFormat = 'png';
  }

  @Mutation
  resetFormat(): void {
    this.imgFormat = 'webp';
  }

  @Mutation
  setForm(value: Form): void {
    this.chosenForm = value;
  }

  @Mutation
  finishedTutorial(): void {
    this.result.doneTutorial = true;
  }

  @Mutation
  resetTutorial(): void {
    this.result.doneTutorial = false;
    if (!this.isDone) {
      this.result.questionsAnswered = 0;
      this.result.correctlyAnswered = 0;
      this.result.overallTime = 0;
      this.result.answers = [];
      this.result.device = Device.Unknown;
    }
  }

  @Mutation
  addAnswer(answer: LettersFantasyWordsResultDto): void {
    if (answer.isCorrect) {
      this.result.correctlyAnswered += 1;
    }
    this.result.questionsAnswered += 1;
    this.result.overallTime += answer.time;
    this.result.answers.push(answer);
    //if no device associated with test, set device
    if (this.result!.device === Device.Unknown) {
      const isTouch =
        'ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        (navigator as any).msMaxTouchPoints > 0;

      this.result!.device = isTouch ? Device.Tablet : Device.Computer;
    }
  }

  @Mutation
  finishedAndSavedTest(): void {
    this.result.done = true;
  }

  @Mutation
  resetDone(): void {
    // INFO have to reset here when saving fails
    // in case of going back to overview the test will not be disabled
    //  and they can not go further
    this.result.done = false;
  }

  @Action({ rawError: true })
  async saveStatus(id: string): Promise<any> {
    axiosRetry(axios, {
      retries: RETRIES,
      retryDelay: () => RETRY_DELAY,
      retryCondition: (error: AxiosError) => {
        if (
          error?.response?.config?.url?.includes(`results/${id}`) &&
          error?.response?.config?.method === 'patch'
        ) {
          return true;
        }
        return false;
      },
    });
    this.result.done = true;
    return await axios.patch(`${API_RESULTS}/${id}`, this.result, {
      headers: { 'Content-Type': 'application/json' },
    });
  }

  @MutationAction({ rawError: true })
  async getTestStatus(args: { id: string; form: string }): Promise<any> {
    const test = Test.fantasy;
    const length = dataA.words.length;
    try {
      const res = await axios.get(
        `${API_RESULTS}/${args.id}/${args.form}/${test}`,
      );
      //set the correct test length if default TestResultDto is returned
      res.data.overallQuestions = length;
      const result = res.data;
      return { result };
    } catch (error) {
      return error;
    }
  }

  @Mutation
  initializeTutorial(audios: any) {
    this.tutorialAudio = audios;
    this.tutorialData = tutorial.words;
  }

  @Mutation
  reset() {
    this.tutorialAudio = {
      bothRead: '',
      maxReads: '',
      lisaReads: '',
      clickCorrectImage: '',
      clickOrangeAnswer: '',
      whoReadCorrectly: '',
      wellDone: '',
      super: '',
      wrongMaxCorrect: '',
      wrongLisaCorrect: '',
      readyGo: '',
    };
  }
}

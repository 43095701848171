import localforage from 'localforage';
import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence, { AsyncStorage } from 'vuex-persist';
import FantasyModule from './modules/FantasyModule';
import LettersModule from './modules/LettersModule';
import RiddleModule from './modules/RiddleModule';
import SnackbarModule from './modules/SnackbarModule';
import SyllableModule from './modules/SyllableModule';
import UserModule from './modules/UserModule';
import WordsModule from './modules/WordsModule';
import SchoolModule from './modules/SchoolModule';
import { KeycloakTokenDto } from '@/api/types';
import ClientModule from './modules/ClientModule';

Vue.use(Vuex);

localforage.config({ storeName: 'lesikus', version: 2 });

const vuexLocal = new VuexPersistence({
  storage: localforage as AsyncStorage,
  asyncStorage: true,
  modules: [
    'user',
    'letters',
    'fantasy',
    'words',
    'riddle',
    'syllables',
    'school',
    'client'
  ],
});

const store = new Vuex.Store({
  getters: {
    authenticated: () => {
      return localStorage.getItem('authenticated') || false;
    },
    refreshToken: () => {
      return localStorage.getItem('refreshToken');
    },
  },
  mutations: {
    login: () => {
      localStorage.setItem('authenticated', 'true');
    },

    logout: async () => {
      localStorage.clear();
      await localforage.clear().then(() => console.log('Cleared!'));
    },
    setToken: (state: any, token: KeycloakTokenDto) => {
      localStorage.setItem('token', token.access_token);
      localStorage.setItem('refreshToken', token.refresh_token);
    },
  },
  actions: {},
  modules: {
    user: UserModule,
    letters: LettersModule,
    words: WordsModule,
    riddle: RiddleModule,
    fantasy: FantasyModule,
    syllables: SyllableModule,
    snackbar: SnackbarModule,
    school: SchoolModule,
    client: ClientModule,
  },
  plugins: [vuexLocal.plugin],
});

export default store;

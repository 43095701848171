import { Client, Test } from '../api/types/api';

export const Testname = {
  [Test.unknown]: 'unknown',
  [Test.letters]: 'Buchstabenjagd',
  [Test.fantasy]: 'Fantasiewörter',
  [Test.syllables]: 'Silbensuche',
  [Test.words]: 'Wörter finden',
  [Test.riddle]: 'Wörterrätsel',
};

export const Pathname = {
  letters: 'buchstabenjagd',
  fantasy: 'fantasiewoerter',
  syllables: 'silbensuche',
  words: 'woerter-finden',
  riddle: 'woerterraetsel',
};
export interface TestOptionItem {
  id: number;
  title: string;
  img: string;
}



export const findWordsImages_a = [
  'Frau',
  'Blume',
  'Mikrofon',
  'Briefmarke',
  'Bein',
  'Klettergerüst',
  'Teller',
  'Blumenstrauß',
  'essen',
  'Wasserhahn',
  'Zirkuszelt',
  'Puppenhaus',
  'Schere',
  'Saftflasche',
  'Sparschwein',
  'Bett',
  'Ast',
  'Krokodil',
  'Lastwagen',
  'Ball',
  'Haustürschlüssel',
  'Nase',
  'Sonnenschirm',
  'Tasse',
  'Uhr',
  'schlafen',
  'Pferd',
  'schreiben',
  'Regentropfen',
  'Junge',
];

export const findWordsImages_b = [
  'Fenster',
  'Tasche',
  'Urkunde',
  'Pinzette',
  'Finger',
  'Matratze',
  'Birne',
  'Einkaufswagen',
  'Hund',
  'Wald',
  'Gewitterblitze',
  'Eichhörnchen',
  'Topfpflanze',
  'rennen',
  'Fledermaus',
  'Blatt',
  'Winterstiefel',
  'Kochtopf',
  'fliegen',
  'Trillerpfeife',
  'Schraubenzieher',
  'Messer',
  'Sandalen',
  'Brille',
  'Tafelkreide',
  'Schmetterlinge',
  'Stoppuhr',
  'Gewitter',
  'Glockenblume',
  'Pferdeschlitten',
];

export const tutorialLettersAudios: string[] = [
  '/audio/tutorials/u1/intro.mp3',
  '/audio/tutorials/u1/HELP3839.mp3',
  '/audio/tutorials/u1/HELP274.mp3',
  '/audio/tutorials/u1/HELP264.mp3',
  '/audio/tutorials/u1/HELP290.mp3',
  '/audio/tutorials/u1/HELP3833.mp3',
  '/audio/tutorials/u1/HELP3834.mp3',
  '/audio/tutorials/u1/HELP3835.mp3',
  '/audio/tutorials/u1/HELP3836.mp3',
];

export const tutorialFantasyAudios: string[] = [
  '/audio/tutorials/u2/HELP256.mp3',
  '/audio/tutorials/u2/HELP291.mp3',
  '/audio/tutorials/u2/HELP3833.mp3',
  '/audio/tutorials/u2/HELP269.mp3',
  '/audio/tutorials/u2/HELP264.mp3',
  '/audio/tutorials/u2/HELP283.mp3',
  '/audio/tutorials/u2/HELP291.mp3',
  '/audio/tutorials/u2/HELP3833.mp3',
  '/audio/tutorials/u2/HELP258.mp3',
  '/audio/tutorials/u2/HELP257.mp3',
  '/audio/tutorials/u2/HELP3836.mp3',
];

export const tutorialSyllableAudios: string[] = [
  '/audio/tutorials/u3/HELP232.mp3',
  '/audio/tutorials/u3/HELP231.mp3',
  '/audio/tutorials/u3/HELP233.mp3',
  '/audio/tutorials/u3/HELP280.mp3',
  '/audio/tutorials/u3/HELP277.mp3',
  '/audio/tutorials/u3/HELP237.mp3',
  '/audio/tutorials/u3/HELP238.mp3',
  '/audio/tutorials/u3/HELP239.mp3',
  '/audio/tutorials/u3/HELP240.mp3',
  '/audio/tutorials/u3/HELP3833.mp3',
  '/audio/tutorials/u3/HELP289.mp3',
  '/audio/tutorials/u3/HELP3841.mp3',
  '/audio/tutorials/u3/HELP3836.mp3',
];

export const tutorialWordsAudios: string[] = [
  '/audio/tutorials/u4/HELP246.mp3',
  '/audio/tutorials/u4/HELP247.mp3',
  '/audio/tutorials/u4/HELP264.mp3',
  '/audio/tutorials/u4/HELP275.mp3',
  '/audio/tutorials/u4/HELP288.mp3',
  '/audio/tutorials/u4/HELP3833.mp3',
  '/audio/tutorials/u4/HELP3834.mp3',
  '/audio/tutorials/u4/HELP3836.mp3',
];

export const tutorialRiddleAudios: string[] = [
  '/audio/tutorials/u5/HELP248.mp3',
  '/audio/tutorials/u5/HELP286.mp3',
  '/audio/tutorials/u5/HELP276.mp3',
  '/audio/tutorials/u5/HELP3818.mp3',
  '/audio/tutorials/u5/HELP3830.mp3',
  '/audio/tutorials/u5/HELP3833.mp3',
  '/audio/tutorials/u5/HELP239.mp3',
  '/audio/tutorials/u5/HELP3836.mp3',
];

export const lettersAudios: string[] = [
  'a',
  'ä',
  'au',
  'äu',
  'b',
  'ch',
  'd',
  'e',
  'ei',
  'eu',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'ö',
  'p',
  'r',
  's',
  'sch',
  'ß',
  't',
  'u',
  'ü',
  'w',
  'z',
];

export const fantasyAAudios: string[] = [
  'at',
  'äuf',
  'aum',
  'äus',
  'but',
  'butmas',
  'daubo',
  'däubo',
  'dumtas',
  'dunka',
  'fäsklop',
  'fäskolp',
  'fesral',
  'flat',
  'fleinerl',
  'fliemal',
  'frat',
  'fröpa',
  'früpa',
  'ift',
  'kep',
  'keut',
  'kläht',
  'kö',
  'költ',
  'kötei',
  'köti',
  'ku',
  'kunda',
  'lonsa',
  'maschot',
  'meu',
  'mü',
  'namschut',
  'ompa',
  'onsal',
  'onta',
  'pak',
  'pfurtong',
  'pönzif',
  'ponzwerf',
  'punzif',
  'pünzwirf',
  'purgon',
  'putt',
  'schaupi',
  'schlo',
  'schnogans',
  'schnugons',
  'schro',
  'staupi',
  'ta',
  'tam',
  'tiff',
  'tölk',
  'tram',
  'treuba',
  'tröba',
  'ün',
  'weschral',
];

export const fantasyBAudios: string[] = [
  'ämri',
  'beit',
  'breinsit',
  'deit',
  'faskilp',
  'fäsklip',
  'feuknort',
  'feum',
  'fläht',
  'fläut',
  'gäpse',
  'gäspe',
  'getmut',
  'gi',
  'gitmop',
  'grond',
  'grott',
  'irm',
  'keufurt',
  'kiet',
  'kreupe',
  'kröpe',
  'märcha',
  'mäuchra',
  'melt',
  'nelt',
  'nöz',
  'önz',
  'päu',
  'pfläu',
  'plö',
  'plü',
  'rämi',
  'ras',
  'räs',
  'rim',
  'scheiwil',
  'scherfeim',
  'schlons',
  'schluns',
  'schünal',
  'schweili',
  'seinbrit',
  'serfien',
  'sönal',
  'talf',
  'tef',
  'tenf',
  'teuschi',
  'tieg',
  'tieram',
  'tin',
  'ucht',
  'üg',
  'urt',
  'waum',
  'wieran',
  'zeuschi',
  'zon',
  'zun',
];

export const ClassType = {
  SAME: 1,
  MIXED: 2,
};

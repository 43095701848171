
import FantasyModule from '@/store/modules/FantasyModule';
import SnackbarModule from '@/store/modules/SnackbarModule';
import UserModule from '@/store/modules/UserModule';
import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';
import GeneralHeader from '../../components/base/GeneralHeader.vue';
import GeneralLayout from '../../components/layout/GeneralLayout.vue';
import FantasyTutorialContainer from '../../components/tests/fantasy/FantasyTutorialContainer.vue';
import FantasyWordContainer from '../../components/tests/fantasy/FantasyWordContainer.vue';
import store from '../../store';

@Component({
  components: {
    FantasyWordContainer,
    FantasyTutorialContainer,
    GeneralLayout,
    GeneralHeader,
  },
})
export default class Fantasiewoerter extends Vue {
  fantasyModule: FantasyModule = getModule(FantasyModule, store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);

  get doneTutorial(): boolean {
    return this.fantasyModule.hasDoneTutorial;
  }

  async beforeRouteLeave(to: any, from: any, next: () => void): Promise<void> {
    await (store as any).restored;
    this.userModule.stopActiveAudio();
    // try {
    //   await this.fantasyModule.saveStatus(this.userModule.currentUser._id);
    //   this.snackbarModule.showMessage({
    //     message: 'Fantasiewörter gespeichert!',
    //     isSuccess: true,
    //   });
    // } catch (error) {
    //   this.snackbarModule.showMessage({
    //     message: 'Leider konnte ich nicht speichern.',
    //     isSuccess: false,
    //   });
    // }

    next();
  }
}

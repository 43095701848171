import {
  Action,
  Module,
  Mutation,
  MutationAction,
  VuexModule,
} from 'vuex-module-decorators';

import {
  CreateUserDto,
  Role,
  UpdateUserDto,
  UserDto,
  BasicSchoolDto,
  Client,
} from '@/api/types/api';
import axios from 'axios';
import download from 'downloadjs';
import {
  API_CLIENTS,
  API_PDF_BOOK,
  API_PDF_EVALUATION,
  API_RESET_PASSWORD,
  API_RESULTS,
  API_USERS,
} from '../../utils/api';
import dayjs from 'dayjs';
import { ClassType } from '@/utils/helpers';

const name = 'school';

@Module({ namespaced: true, name })
export default class SchoolModule extends VuexModule {
  teachers: UserDto[] = [
    {
      _id: '',
      username: '',
      firstname: '',
      lastname: '',
      email: '',
      role: Role.teacher,
      schoolId: 0,
      grade: '',
      doneA: false,
      doneB: false,
      isMiddleschool: false,
      isTutorialOnly: false,
    },
  ];
  classStudents: UserDto[] = [];
  patients: UserDto[] = [];
  school: BasicSchoolDto = {
    name: '',
    classes: [],
    licenseUntil: '',
    clientType: Client.school,
    schoolId: null,
    slug: '',
    country: '',
    isTutorialOnly: false,
    validTests: 0,
  };

  get schoolTeacher(): UserDto[] {
    return this.teachers;
  }

  get myStudents(): UserDto[] {
    return this.classStudents;
  }

  get classes(): string[] {
    return this.school.classes;
  }

  get schoolName(): string {
    return this.school.name;
  }

  get suffix(): string {
    return `_${this.school.schoolId}`;
  }

  get clientType(): Client {
    return this.school.clientType;
  }

  get licenseExpired(): boolean {
    const now = new Date();
    // INFO if now is greater than license expired
    return now.getTime() > new Date(this.school.licenseUntil).getTime();
  }

  get validDaysLeft(): number {
    const now = dayjs().hour(0).minute(0).second(0).millisecond(0);
    const end = dayjs(this.school.licenseUntil)
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0);
    return end.diff(now, 'day');
    // return Math.floor(diff / (1000 * 60 * 60 * 24));
  }

  get shouldFetchSchoolInfo(): boolean {
    return (
      this.school.name === '' &&
      this.school.licenseUntil === '' &&
      this.school.schoolId === null
    );
  }

  get isTutorialOnlyVersion(): boolean {
    return this.school.isTutorialOnly.toString() === 'true';
  }

  get schoolId(): string | number | null {
    return this.school.schoolId ?? null;
  }

  get slug(): string {
    return this.school.slug;
  }

  get hasNoValidTestsLeft(): boolean {
    const numOfTests = this.school.validTests ?? 0;
    return numOfTests <= 0;
  }

  get hasMissingLevelInStudents(): boolean {
    return this.classStudents.some(
      (student) => student.level === undefined || student.level === null,
    );
  }

  get isSchoolClient(): boolean {
    return this.school.clientType === Client.school;
  }

  get isTherapyClient(): boolean {
    return this.school.clientType === Client.therapy;
  }

  @MutationAction({ rawError: true })
  async getBasicSchoolInfo(schoolId: number | string | null): Promise<any> {
    if (schoolId === null || schoolId === '') return;
    const res = await axios.get(`${API_USERS}/school/${schoolId}`, {
      headers: { 'Content-Type': 'application/json' },
    });
    const school = res.data;
    localStorage.setItem('version', school.country);
    return { school: school };
  }

  // @Action
  // async getVersionInfo(schoolId: number | string | null): Promise<any> {
  //   if (schoolId === null || schoolId === '') return;
  //   const res = await axios.get(`${API_USERS}/school/${schoolId}`, {
  //     headers: { 'Content-Type': 'application/json' },
  //   });

  //   return res.data.isTutorialOnly;
  // }

  // @MutationAction
  // async getSchool(schoolId: number): Promise<any> {
  //   const res = await axios.get(`${API_USERS}/classes/${schoolId}`, {
  //     headers: { 'Content-Type': 'application/json' },
  //   });
  //   const classes = res.data;
  //   console.log('Found classes for group', classes);
  //   return { allClasses: classes };
  // }

  // @Action
  // async getClasses(schoolId: number): Promise<string[]> {
  //   try {
  //     const res = await axios.get(`${API_GET_CLASSES}/${schoolId}`, {
  //       headers: { 'Content-Type': 'application/json' },
  //     });
  //     return res.data.classes;
  //   } catch (error) {
  //     console.log(error);
  //     return [];
  //   }
  // }

  @Action
  async decreaseValidTests(slug: string): Promise<UserDto[]> {
    try {
      return await axios.patch(`${API_CLIENTS}/valid-tests/${slug}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  @Action
  async getTeachersOrTherapists(): Promise<UserDto[]> {
    try {
      const res = await axios.get(`${API_USERS}/filter`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const teachers: UserDto[] = res.data;
      return teachers;
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  @Action({ rawError: true })
  async getStudents(grade?: string): Promise<UserDto[]> {
    const students = await axios
      .get(`${API_USERS}/filter?grade=${grade}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => res.data);
    return students;
  }

  @MutationAction({ rawError: true })
  async getClassStudents(grade?: string): Promise<any> {
    const students = await axios
      .get(`${API_USERS}/filter?grade=${grade}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => res.data);
    return {
      classStudents: students,
    };
  }

  @MutationAction({ rawError: true })
  async getPatients(): Promise<any> {
    const data: UserDto[] = await axios
      .get(`${API_USERS}/patients`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => res.data);
    return {
      patients: data,
    };
  }

  @Action({ rawError: true })
  async addUser(createUser: CreateUserDto): Promise<UserDto[]> {
    const res = await axios.post(`${API_USERS}`, createUser, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const teachers: UserDto[] = res.data;
    return teachers;
  }

  @Action({ rawError: true })
  async updateUser(args: {
    id: string;
    updateUser: UpdateUserDto;
  }): Promise<UserDto> {
    const res = await axios.patch(`${API_USERS}/${args.id}`, args.updateUser, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return res.data;
  }

  @Action({ rawError: true })
  async deleteUser(id: string): Promise<any> {
    // INFO also delete test results on deleting user
    await axios.delete(`${API_RESULTS}/${id}`);
    const res = await axios.delete(`${API_USERS}/${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return res;
  }

  @Action({ rawError: true })
  async importStudents(students: CreateUserDto[]): Promise<any> {
    const res = await axios.post(`${API_USERS}/students`, students, {
      headers: { 'Content-Type': 'application/json' },
    });
    return res;
  }

  @Action({ rawError: true })
  async sendPasswordMail(id: string): Promise<any> {
    const res = await axios.post(`${API_RESET_PASSWORD}/${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return res;
  }

  @Action({ rawError: true })
  async getClasslist(filename: string): Promise<void> {
    await axios
      .get(`${API_PDF_EVALUATION}/classList`, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/pdf',
          Accept: 'application/pdf',
        },
      })
      .then((res) => {
        const content = res.headers['content-type'];
        download(res.data, `${filename}`, content);
      });
  }

  @Action({ rawError: true })
  async getSingleCode(id: string): Promise<void> {
    await axios
      .get(`${API_PDF_EVALUATION}/classList/code/${id}`, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/pdf',
          Accept: 'application/pdf',
        },
      })
      .then((res) => {
        const content = res.headers['content-type'];
        download(res.data, `Zugangscode`, content);
        // const file = new Blob([res.data], { type: 'application/pdf;base64' });
        // const fileURL = URL.createObjectURL(file);
        // window.open(fileURL);
      });
  }

  @Action({ rawError: true })
  async downloadBook(book: string): Promise<void> {
    await axios
      .get(`${API_PDF_BOOK}/${book}`, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/pdf',
          Accept: 'application/pdf',
        },
      })
      .then((res) => {
        const title = book === 'manual' ? 'LEO-Handbuch' : 'LEO-Praxisbuch';
        const content = res.headers['content-type'];
        download(res.data, title, content);
      });
  }

  @Mutation
  reset(): void {
    this.school = {
      name: '',
      classes: [],
      licenseUntil: '',
      clientType: Client.school,
      schoolId: null,
      slug: '',
      country: '',
      isTutorialOnly: false,
    };
    this.patients = [];
    this.classStudents = [];
  }
}

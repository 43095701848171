
import BackButton from '../../components/admin/login/BackButton.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import AdminLayout from '../../components/layout/AdminLayout.vue';

@Component({
  components: { AdminLayout, BackButton },
})
export default class AdminImpressum extends Vue {}

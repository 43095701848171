import { Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true, name: 'snackbar' })
export default class SnackbarModule extends VuexModule {
  show = false;
  text = '';
  timeout = 3000;
  colour = 'success';
  success = false;

  get showSnackbar(): boolean {
    return this.show;
  }

  get message(): string {
    return this.text;
  }

  get color(): string {
    return this.colour;
  }

  get isSuccess(): boolean {
    return this.success;
  }

  @Mutation
  showMessage(args: {
    message: string;
    isSuccess: boolean;
    time?: number;
  }): void {
    this.show = true;
    this.success = args.isSuccess;
    this.text = args.message;
    this.colour = args.isSuccess ? 'success' : 'brown darken-4';
    const time = args.time || 3500;
    setTimeout(async () => {
      this.show = false;
      this.text = '';
    }, time);
  }
}

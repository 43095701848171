
import Vue from 'vue';
import Component from 'vue-class-component';
import AdminLayout from '../../components/layout/AdminLayout.vue';
import StudentDashboard from '../../components/admin/students/StudentDashboard.vue';

@Component({
  components: { AdminLayout, StudentDashboard },
})
export default class StudentPage extends Vue {}

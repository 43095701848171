
import Vue from 'vue';
import Component from 'vue-class-component';
import GeneralHeader from '../../components/base/GeneralHeader.vue';
import GeneralLayout from '../../components/layout/GeneralLayout.vue';

@Component({
  components: {
    GeneralLayout,
    GeneralHeader,
  },
})
export default class Login extends Vue {
  get title(): string {
    return 'LEO';
  }

  get subtitle(): string {
    return 'Für lesestarke Kids!';
  }

  get leo(): string {
    return require('@/assets/images/lion_small.png');
  }
}

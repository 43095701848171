
import SnackbarModule from '@/store/modules/SnackbarModule';
import SyllableModule from '@/store/modules/SyllableModule';
import UserModule from '@/store/modules/UserModule';
import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';
import GeneralHeader from '../../components/base/GeneralHeader.vue';
import GeneralLayout from '../../components/layout/GeneralLayout.vue';
import SyllablesTestContainer from '../../components/tests/syllables/SyllablesTestContainer.vue';
import SyllablesTutorialContainer from '../../components/tests/syllables/SyllablesTutorialContainer.vue';
import store from '../../store';

@Component({
  components: {
    SyllablesTestContainer,
    SyllablesTutorialContainer,
    GeneralLayout,
    GeneralHeader,
  },
})
export default class Buchstabenjagd extends Vue {
  syllableModule: SyllableModule = getModule(SyllableModule, store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);

  get doneTutorial(): boolean {
    return this.syllableModule.hasDoneTutorial;
  }

  async beforeRouteLeave(to: any, from: any, next: () => void): Promise<void> {
    this.userModule.stopActiveAudio();

    // try {
    //   await this.syllableModule.saveStatus(this.userModule.currentUser._id);
    //   this.snackbarModule.showMessage({
    //     message: 'Silbensuche gespeichert!',
    //     isSuccess: true,
    //   });
    // } catch (error) {
    //   this.snackbarModule.showMessage({
    //     message: 'Leider konnte ich nicht speichern.',
    //     isSuccess: false,
    //   });
    // }
    next();
  }
}
